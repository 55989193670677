import React from 'react';
import { ChevronLeft } from 'baseui/icon';
import Base from '@/components/Layouts/Base';
import PageTitle from '@/components/Common/PageTitle';
import { styled } from 'baseui';
import { Input } from 'baseui/input';
import { Checkbox } from 'baseui/checkbox';
import { Button, ButtonProps, SIZE, SHAPE, KIND } from 'baseui/button';
import { Block } from 'baseui/block';
import Theme from '@/constants/Theme';
import Hover from '@/components/Common/Hover';
import Wrapper from '@/components/Common/Wrapper';

import { Label2, Paragraph3, Paragraph4 } from 'baseui/typography';
import { useInputState, useBaseRouteParams, useInternalLink } from '@/helpers/hooks';
import { useHistory } from 'react-router-dom';
import { createOrder } from '@/api/order';
import cartSlice, { useCart } from '@/stores/cart';
import { useDispatch } from 'react-redux';
import orderSlice from '@/stores/order';
import restaurantSlice, { useRestaurant } from '@/stores/restaurant';
import { fetchRestaurant } from '@/api/restaurant';
import { AxiosError } from 'axios';

function isMobileNumber(number: string): boolean {
  return number.match(/^0[9876]0[-]?\d{4}[-]?\d{4}$/) !== null;
}

export default function Register() {
  const params = useBaseRouteParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const cart = useCart();
  const restaurant = useRestaurant();
  const name = useInputState('');
  const tel = useInputState('');
  const [isEnabledContact, setIsEnabledContact] = React.useState(true);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const linkToComplete = useInternalLink('/cart/complete');
  const linkToTop = useInternalLink();
  const canClickNext = React.useMemo(() => name.value.length > 0 && tel.value.length > 0, [name, tel]);

  React.useEffect(() => {
    (async () => {
      if (initialized) {
        return;
      }
      setInitialized(true);
      const res = await fetchRestaurant(params);
      if (res.slug !== params.brandSlug) {
        history.push('/');
        return;
      }

      if (res.redirectUrl && res.redirectUrl.length > 0) {
        window.location.href = res.redirectUrl;
        return;
      }
      dispatch(restaurantSlice.actions.initialize(res));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (!initialized) {
    return null;
  }
  if (cart.list.length === 0 || (!restaurant.pristine && restaurant.data.isOpen === 2)) {
    history.push(linkToTop);
  }

  const handlePressGoBack = () => history.goBack();
  const handleClickPurchase = () => {
    if (!isMobileNumber(tel.value)) {
      setErrorMessage('利用できない電話番号です');
      return;
    }
    setErrorMessage('');
    createOrder({
      restaurantId: params.restaurantId,
      name: name.value,
      tel: tel.value,
      cartItems: cart.list,
      isEnabledContact,
      receivedAt: cart.receiveAt,
    })
      .then((res) => {
        dispatch(cartSlice.actions.flush());
        dispatch(orderSlice.actions.add(res));
        history.push(linkToComplete);
      })
      .catch((error: AxiosError<{ errors: Array<{ message: string }> }>) => {
        if (!error.response?.data) {
          return;
        }
        const { errors } = error.response.data;
        const errMsg = errors && errors[0] ? errors[0].message : '';
        setErrorMessage(errMsg === 'not acceptable' ? '受付時間外です' : '利用できない電話番号です');
      });
  };
  return (
    <>
      <PageTitle title='Register' />
      <Base>
        <HeaderParts>
          <Hover>
            <span onClick={handlePressGoBack}>
              <ChevronLeft size={24} />
            </span>
          </Hover>
          <span>お客様情報</span>
          <span />
        </HeaderParts>
        <Wrapper size='small'>
          <Paragraph3 display='flex' justifyContent='center' margin='2rem'>
            引き渡しの時に、本人確認させていただきます。
          </Paragraph3>
          <Block margin='2rem'>
            <Label2 marginBottom='0.5rem'>お名前</Label2>
            <Input value={name.value} onChange={name.handleChange} />
          </Block>
          <Block margin='2rem'>
            <Label2 marginBottom='0.5rem'>電話番号</Label2>
            <Input value={tel.value} onChange={tel.handleChange} type='tel' />
            {errorMessage.length > 0 && <Paragraph4 color='red'>{errorMessage}</Paragraph4>}
            <Paragraph4 display='inline-block'>
              ※ハイフンは不要です
              <br />
              ※SMSを受信できる電話番号を記載ください
            </Paragraph4>
          </Block>
          <Block margin='2rem'>
            <Checkbox checked={isEnabledContact} onChange={() => setIsEnabledContact(!isEnabledContact)}>
              SMSでキャンペーン情報を受け取る
            </Checkbox>
          </Block>

          <Block margin='2rem' padding='1rem' backgroundColor='primary100'>
            <Paragraph4 color={Theme.colors.primary400}>
              <a href='/terms' target='_blank'>
                利用規約＆プライバシーポリシー
              </a>
              をご確認ください。ご同意いただける場合、以下のボタンを押して注文を確定してください。
            </Paragraph4>
          </Block>

          <Block margin='1rem'>
            <Button onClick={handleClickPurchase} {...buttonPrimary} disabled={!canClickNext}>
              同意して注文確定する
            </Button>
          </Block>
        </Wrapper>
      </Base>
    </>
  );
}

const buttonPrimary: ButtonProps = {
  size: SIZE.large,
  kind: KIND.primary,
  shape: SHAPE.pill,
  overrides: {
    BaseButton: {
      style: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
};

const HeaderParts = styled('div', ({ $theme }) => ({
  height: '3.5rem',
  width: '100%',
  maxWidth: $theme.breakpoints.large,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '1rem',
  paddingRight: '2.25rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: $theme.lighting.shadow400,
}));
