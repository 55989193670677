import { createTheme } from 'baseui';

const myTokens = {
  // define various brand colors here...
  colors: {
    accent: '#F66C34',
    primary: '#4f524e',
  },
  typography: {
    headlineLarge: '1.5rem', // 32px
    headline: '1.25rem', // 20px
    subHeadline: '1rem',
    base: '16px',
    body: '.9375rem', // 15px
    detail: '.815rem', // 13px
    caption: '.75rem', // 12px
  },
  spacing: {
    none: '0',
    extraSmall: '.25rem',
    small: '.5rem',
    medium: '1rem',
    large: '2rem',
    extraLarge: '4rem',
    extraExtraLarge: '8rem',
    extraExtraExtraLarge: '16rem',
  },
};

const myThemePrimitives = {
  // define all the various primitives for my theme...
  // adding in custom "accent" primitives
  accent: myTokens.colors.accent,
  accent50: myTokens.colors.accent,
  accent100: myTokens.colors.accent,
  accent200: myTokens.colors.accent,
  accent300: myTokens.colors.accent,
  accent400: myTokens.colors.accent,
  accent500: myTokens.colors.accent,
  accent600: myTokens.colors.accent,
  accent700: myTokens.colors.accent,
  primaryA: '#454c42', // basic font color
  // Typography
  // Paragraph fontSize = Label fontSize, but fontWeight.
  primaryFontFamily: 'roboto, Noto Sans JP',
  ParagraphXSmall: myTokens.typography.caption, // Paragraph4
  ParagraphSmall: myTokens.typography.detail, // Paragraph3
  ParagraphMedium: myTokens.typography.body, // Paragraph2
  ParagraphLarge: myTokens.typography.base, // Paragraph1
  LabelXSmall: myTokens.typography.caption, // Label4
  LabelSmall: myTokens.typography.detail, // Label3
  LabelMedium: myTokens.typography.body, // Label2
  LabelLarge: myTokens.typography.base, // Label1
  HeadingSmall: myTokens.typography.subHeadline, // H4
  HeadingMedium: myTokens.typography.headline, // H3
  HeadingLarge: myTokens.typography.headlineLarge, // H2
};

const overrides = {
  colors: {
    primary: myTokens.colors.primary,
    buttonPrimaryFill: myThemePrimitives.accent,
  },
  breakpoints: {
    small: '30rem',
    medium: '50rem',
    large: '1136px',
  },
  typography: {
    HeadingSmall: myTokens.typography.subHeadline, // H4
    HeadingMedium: myTokens.typography.headline, // H3
    HeadingLarge: myTokens.typography.headlineLarge, // H2
  },
};

const theme = createTheme(myThemePrimitives, overrides);
export default theme;
