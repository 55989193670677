import React from 'react';
import { useStyletron } from 'baseui';
import Theme from '@/constants/Theme';

const HorizontalScrolableNav: React.FC = ({ children }) => {
  const [css] = useStyletron();
  return (
    <div className={css(Wrapper)}>
      <div className={css(Mask)}>
        <ul className={css(Lists)}>
          {React.Children.map(children, (child) => (
            <li className={css(List)}>{child}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Wrapper = {
  width: '100%',
  maxWidth: Theme.breakpoints.medium,
  marginLeft: 'auto',
  marginRight: 'auto',
  overflow: 'hidden',
  'white-space': 'nowrap',
  '::-webkit-scrollbar': {
    display: 'none',
  },
};
const Mask = {
  width: '100%',
  'overflow-x': 'auto',
  '-webkit-overflow-scrolling': 'touch',
};
const Lists = {
  display: 'table',
  paddingLeft: 0,
  marginLeft: '1rem',
  marginBottom: 0,
  paddingTop: '0.2rem',
  paddingBottom: '0.2rem',
};
const List = {
  display: 'table-cell',
  paddingRight: '1rem',
};

export default HorizontalScrolableNav;
