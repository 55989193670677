import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' focusable='true' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.34 3.03L20.5 3c.28 0 .5.22.5.5v15.12c0 .23-.15.41-.36.48L15 21l-6-2.1-5.34 2.07-.16.03c-.28 0-.5-.22-.5-.5V5.38c0-.23.15-.41.36-.48L9 3l6 2.1 5.34-2.07zM14 6.87l-4-1.4v11.66l4 1.4V6.87zm-9-.41l3-1.01v11.7l-3 1.16V6.46zm11 12.09l3-1.01V5.7l-3 1.16v11.69z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgComponent;
