import React from 'react';

import Bootstrap from './Bootstrap';
import Router from './Router';

import { store, persistor } from './stores';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Bootstrap>
          <Router />
        </Bootstrap>
      </PersistGate>
    </Provider>
  );
}
