import React from 'react';
import { Box, Flex, Link } from 'rebass';
import LogoOrdee from '@/components/Svg/LogoOrdee'

export default function Seo() {
    return(
      <Box as="header" sx={{position:'absolute',top:0,width:'100%'}} p={[3,3,4]} >
        <Flex justifyContent='space-between' alignItems='center'>
          <Link width={[100,140,140]} href='/' sx={{color:'black', ':hover':{color:'black'}}}>
            <LogoOrdee title='注文管理サービスOrdee' height='100%' width='100%' />
          </Link>
          <Box>
            <Link variant='undelineLink' href='/register'>
              資料を請求する
            </Link>          
          </Box>
        </Flex>
      </Box>
       
    )
  }  