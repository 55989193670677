import React from 'react';

import { Label2 } from 'baseui/typography';
import { Block } from 'baseui/block';
import Hover from '@/components/Common/Hover';
import HorizontalScrolableNav from '@/components/Common/HorizontalScrolableNav';

import { Category } from '@/typings/entity/category';

interface Props {
  categories: Category[];
  selectScrollTarget: (categoryId: number) => void;
}

export default function CategoryList(props: Props) {
  return React.useMemo(
    () => (
      <HorizontalScrolableNav>
        {props.categories.map((category) => (
          <Block key={category.id} paddingTop='0.5rem' paddingBottom='0.5rem'>
            <Hover>
              <div onClick={() => props.selectScrollTarget(category.id)}>
                <Label2>{category.name}</Label2>
              </div>
            </Hover>
          </Block>
        ))}
      </HorizontalScrolableNav>
    ),
    [props],
  );
}
