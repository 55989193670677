import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'baseui';
import Theme from '@/constants/Theme';
import Hover from '@/components/Common/Hover';
import { ArrowLeft } from 'baseui/icon';
import { useInternalLink } from '@/helpers/hooks';

export default function Header() {
  const link = useInternalLink();
  return React.useMemo(
    () => (
      <Wrapper>
        <Hover>
          <Link to={link} style={{ color: 'white' }}>
            <ArrowLeft size='36px' />
          </Link>
        </Hover>
      </Wrapper>
    ),
    [link],
  );
}

const Wrapper = styled('div', () => ({
  width: '100%',
  height: '3.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '0.5rem',
  paddingRight: '1rem',
  maxWidth: Theme.breakpoints.large,
  marginLeft: 'auto',
  marginRight: 'auto',
  color: Theme.colors.primary,
  background: 'linear-gradient(168deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 47%)',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 2,
}));
