import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 142 94" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139 3H3v88h136V3zM3 0H0v94h142V0H3zm11 8H8v6h6V8zm0 39H8v30h6V47zM8 16h6v29H8V16zm6 63H8v6h6v-6zm92-55H36V8h70v16zm-58-8a4 4 0 11-8 0 4 4 0 018 0zm58 26H36V26h70v16zm-58-8a4 4 0 11-8 0 4 4 0 018 0zM36 60h70V44H36v16zm8-4a4 4 0 100-8 4 4 0 000 8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
