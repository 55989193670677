/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch } from 'react-redux';
import cartModule from '@/stores/cart';
import { useHistory, useParams } from 'react-router-dom';
import { Block } from 'baseui/block';
import { Button, SIZE, SHAPE } from 'baseui/button';
import Base from '@/components/Layouts/Base';
import PageTitle from '@/components/Common/PageTitle';
import MenuItem from '@/components/Common/MenuItem';
import HeaderBack from '@/components/Common/HeaderBack';
import Wrapper from '@/components/Common/Wrapper';
import { formatPrice } from '@/helpers/format';
import NotFound from '../NotFound';
import ItemCounter from '@/components/Common/ItemCounter';
import { Category } from '@/typings/entity/category';
import { Item as ItemEntity } from '@/typings/entity/item';
import { useBaseRouteParams, useInternalLink } from '@/helpers/hooks';
import restaurantSlice, { useRestaurant } from '@/stores/restaurant';
import { fetchRestaurant } from '@/api/restaurant';

interface RouteParams {
  id: string;
}

export default function Item() {
  const baseParams = useBaseRouteParams();
  const params = useParams<RouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const restaurant = useRestaurant();
  const [quantity, setQuantity] = React.useState<number>(1);
  const itemId = parseInt(params.id, 10);
  const linkToCart = useInternalLink('/cart');

  React.useEffect(() => {
    (async () => {
      if (!restaurant.pristine) {
        return;
      }

      const res = await fetchRestaurant(baseParams);
      if (res.slug !== baseParams.brandSlug) {
        history.push('/');
        return;
      }

      if (res.redirectUrl && res.redirectUrl.length > 0) {
        window.location.href = res.redirectUrl;
        return;
      }
      dispatch(restaurantSlice.actions.initialize(res));
    })();
  }, [baseParams]);

  if (restaurant.pristine) {
    return null;
  }

  const item = pickItem(itemId, restaurant.data.categories);
  if (!item) {
    return <NotFound />;
  }
  const add = () => {
    dispatch(cartModule.actions.add({ itemId, quantity }));
    history.push(linkToCart);
  };
  const incrementCount = () => {
    setQuantity(quantity + 1);
  };
  const decrementCount = () => {
    if (quantity <= 1) {
      return;
    }
    setQuantity(quantity - 1);
  };

  const totalPrice = quantity * item.unitPrice;
  const isSoldout = item.status !== 1;
  return (
    <>
      <PageTitle title='Top' />
      <Base>
        <HeaderBack />
        <Wrapper size='medium'>
          <MenuItem size='large' item={item} restaurant={restaurant.data} />
          {!isSoldout && (
            <Block margin='1rem auto' display='flex' justifyContent='center'>
              <ItemCounter count={quantity} increment={incrementCount} decrement={decrementCount} size='large' />
            </Block>
          )}
          <Block padding='1rem' marginBottom='2rem'>
            {isSoldout && (
              <Button disabled={true} size={SIZE.large} shape={SHAPE.pill} overrides={overrides}>
                <span style={styles.soldout}>品切れ中</span>
              </Button>
            )}
            {!isSoldout && (
              <Button onClick={add} size={SIZE.large} shape={SHAPE.pill} overrides={overrides}>
                <span>カートに追加する</span>
                <span>{formatPrice(totalPrice)}円</span>
              </Button>
            )}
          </Block>
        </Wrapper>
      </Base>
    </>
  );
}

const styles = {
  soldout: {
    width: '100%',
  },
};

const overrides = {
  BaseButton: {
    style: () => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    }),
  },
};

function pickItem(itemId: number, categories: Category[]) {
  const items: ItemEntity[] = [];
  categories.forEach((c) => c.items.forEach((i) => items.push(i)));
  return items.find((i) => i.id === itemId);
}
