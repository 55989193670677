import * as React from 'react';
import theme from '@/constants/Theme';
import { Label3 } from 'baseui/typography';

interface Props {
  isOpen: number;
}

export default function OpeningStatusLabel(props: Props) {
  return (
    <>
      {props.isOpen === 0 && (
        <Label3 display='inline-block' color={theme.colors.positive} marginRight='4px'>
          営業中
        </Label3>
      )}
      {props.isOpen === 1 && (
        <Label3 display='inline-block' color={theme.colors.positive} marginRight='4px'>
          予約受付中
        </Label3>
      )}
      {props.isOpen === 2 && (
        <Label3 display='inline-block' color={theme.colors.negative} marginRight='4px'>
          時間外
        </Label3>
      )}
    </>
  );
}
