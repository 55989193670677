import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import { Restaurant } from '@/typings/entity/restaurant';

export interface RestaurantState {
  pristine: boolean;
  data: Restaurant;
}

const initialState: RestaurantState = {
  pristine: true,
  data: {
    id: 0,
    slug: '',
    brandId: 0,
    name: 'Loading',
    tel: '',
    address: '',
    mapUrl: '',
    redirectUrl: '',
    openingTime: [],
    isOpen: 2,
    stackedTime: 0,
    brandName: '',
    categories: [],
  },
};

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    initialize(state, value: PayloadAction<Restaurant>) {
      state.pristine = false;
      state.data = value.payload;
    },
  },
});

export const useRestaurant = () => {
  return useSelector((state: AppState) => state.restaurant);
};

export default restaurantSlice;
