import React from 'react';
import { Link, useHistory } from 'react-router-dom';
// import { useDispatch } from "react-redux"
// import cartModule, { useAuth as cartModuleUseAuth } from "@/stores/cart"

import { Delete } from 'baseui/icon';
import Base from '@/components/Layouts/Base';
import PageTitle from '@/components/Common/PageTitle';
import { styled } from 'baseui';
import Theme from '@/constants/Theme';
import Wrapper from '@/components/Common/Wrapper';
import CartContents from '@/components/Cart/CartContents';
import cartSlice, { useCart } from '@/stores/cart';
import { useBaseRouteParams, useInternalLink } from '@/helpers/hooks';
import { fetchRestaurant } from '@/api/restaurant';
import restaurantSlice, { useRestaurant } from '@/stores/restaurant';
import { useDispatch } from 'react-redux';
import { CartItem } from '@/typings/entity/cart_item';
import { Restaurant } from '@/typings/entity/restaurant';
import { Item } from '@/typings/entity/item';

export default function Cart() {
  const params = useBaseRouteParams();
  const history = useHistory();
  const cart = useCart();
  const restaurant = useRestaurant();
  const dispatch = useDispatch();
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [cartItems, setCartItems] = React.useState<CartItem[]>(cart.list);
  const linkToTop = useInternalLink();
  React.useEffect(() => {
    (async () => {
      if (initialized) {
        return;
      }
      setInitialized(true);
      const res = await fetchRestaurant(params);
      if (res.slug !== params.brandSlug) {
        history.push('/');
        return;
      }
      if (res.redirectUrl && res.redirectUrl.length > 0) {
        window.location.href = res.redirectUrl;
        return;
      }

      dispatch(restaurantSlice.actions.initialize(res));
      const items = memoizeItemList(res);
      cart.list.forEach((ci) => {
        const item = items.find((i) => i.id === ci.itemId);
        if (!item || item.status !== 1) {
          dispatch(cartSlice.actions.remove(ci.itemId));
        }
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  const totalPrice = calcTotalPrice(cart.list, memoizeItemList(restaurant.data));
  if (!restaurant) {
    return null;
  }
  return (
    <>
      <PageTitle title='Cart' />
      <Base>
        <HeaderParts>
          <Link to={linkToTop} style={{ color: Theme.colors.primaryA }}>
            <Delete size={24} />
          </Link>
          <span>ご注文の確認</span>
          <span />
        </HeaderParts>
        <Wrapper size='small'>
          <CartContents
            restaurant={restaurant.data}
            cartItems={cartItems}
            totalPrice={totalPrice}
            setCartItems={setCartItems}
            receivedAt={cart.receiveAt}
          />
        </Wrapper>
      </Base>
    </>
  );
}

const HeaderParts = styled('div', ({ $theme }) => ({
  height: '3.5rem',
  width: '100%',
  maxWidth: $theme.breakpoints.large,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '1rem',
  paddingRight: '3rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: $theme.lighting.shadow400,
}));

function memoizeItemList(restaurant: Restaurant) {
  const items: Item[] = [];
  restaurant.categories.forEach((c) => c.items.forEach((i) => items.push(i)));
  return items;
}

function calcTotalPrice(cartItems: CartItem[], items: Item[]) {
  return cartItems
    .map((ci) => {
      const item = items.find((i) => i.id === ci.itemId);
      if (!item || item.status !== 1) {
        return 0;
      }
      return ci.quantity * item.unitPrice;
    })
    .reduce((a, b) => a + b, 0);
}
