import React from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';

import ScrollToTopOnPathChanges from '@/components/Common/ScrollToTopOnPathChanges';
import { routes } from '@/routes';
import GaListener from '@/GaListener';

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTopOnPathChanges>
        <Switch>
          {routes.map((route) => (
            <GaListener key={route.name} {...route} />
          ))}
        </Switch>
      </ScrollToTopOnPathChanges>
    </BrowserRouter>
  );
}
