import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export default function makeAxiosClient() {
  const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:4000',
  });
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      return config;
    },
    (err: AxiosError) => {
      return Promise.reject(err);
    },
  );
  axios.interceptors.response.use(
    (res: AxiosResponse) => {
      if (res.data.errors) {
        return Promise.reject(res);
      }
      return Promise.resolve(res);
    },
    (err: AxiosError) => Promise.reject(err),
  );
  return axios;
}
