import * as React from 'react';

import Base from '@/components/Layouts/Base';
import PageTitle from '@/components/Common/PageTitle';
import { ThemeProvider } from 'baseui';
import Theme from '@/constants/Theme';
import { Block } from 'baseui/block';
import { H3 } from 'baseui/typography';
import Body from '@/components/Terms/Body';

export default function Terms() {
  return (
    <>
      <PageTitle title='利用規約・プライバシーポリシー' />
      <Base>
        <ThemeProvider theme={Theme}>
          <Block margin='1rem'>
            <H3>利用規約・プライバシーポリシー</H3>
            <Body />
          </Block>
        </ThemeProvider>
      </Base>
    </>
  );
}
