import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import { Order } from '@/typings/entity/order';

export interface OrderState {
  pristine: boolean;
  list: Order[];
}

const initialState: OrderState = {
  pristine: true,
  list: [],
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    initialize(state) {
      state.pristine = false;
      state.list = [];
    },
    add(state, value: PayloadAction<Order>) {
      state.list.push(value.payload);
    },
  },
});

export const useOrder = () => {
  return useSelector((state: AppState) => state.order);
};

export default orderSlice;
