import React from 'react';
import Base from '@/components/Layouts/Base';
import PageTitle from '@/components/Common/PageTitle';
import Header from '@/components/Common/Header';
import RestaurantHeader from '@/components/Common/RestaurantHeader';

import CategoryList from '@/components/Top/CategoryList';
import Wrapper from '@/components/Common/Wrapper';
import ListItem from '@/components/Top/ListItem';
import { styled } from 'baseui';
import ItemListHeader from '@/components/Top/ItemListHeader';
import { fetchRestaurant } from '@/api/restaurant';
import { useBaseRouteParams } from '@/helpers/hooks';
import restaurantSlice, { useRestaurant } from '@/stores/restaurant';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function Top() {
  const params = useBaseRouteParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const restaurant = useRestaurant();
  const [scrollTargetId, setScrollTarget] = React.useState<number | null>(null);
  React.useEffect(() => {
    (async () => {
      if (!restaurant.pristine) {
        return;
      }
      const res = await fetchRestaurant(params);
      if (res.slug !== params.brandSlug) {
        history.push('/');
        return;
      }
      if (res.redirectUrl && res.redirectUrl.length > 0) {
        window.location.href = res.redirectUrl;
        return;
      }
      dispatch(restaurantSlice.actions.initialize(res));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  const categories = React.useMemo(
    () => restaurant.data.categories.filter((c) => c.items.length > 0).sort((a, b) => a.priority - b.priority),
    [restaurant.data],
  );
  return (
    <>
      <PageTitle title='Top' />
      <Base>
        <Header />
        <RestaurantHeader restaurant={restaurant.data} />
        <CategoryList categories={categories} selectScrollTarget={setScrollTarget} />
        <Wrapper size='medium'>
          {categories.map((c) => (
            <div key={c.id}>
              <ItemListHeader scrollTargetId={scrollTargetId} category={c} />
              <MenuWrapper>
                {c.items.map((i) => (
                  <ListItem key={i.id} item={i} restaurant={restaurant.data} />
                ))}
              </MenuWrapper>
            </div>
          ))}
        </Wrapper>
      </Base>
    </>
  );
}

const MenuWrapper = styled('div', ({ $theme }) => ({
  width: '100%',
  maxWidth: $theme.breakpoints.large,
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(8.5rem, 1fr))',
  gridAutoRows: '1fr',
  gap: '2rem 1rem',
  padding: '0 0.75rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '4rem',
}));
