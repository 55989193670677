import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 9h2V2h2v7c0 2.21-1.79 4-4 4v9H7v-9c-2.21 0-4-1.79-4-4V2h2v7h2V2h2v7zm7 5V6c0-1.76 2.24-4 5-4v20h-2v-8h-3z'
        fill='CurrentColor'
      />
    </svg>
  );
}

export default SvgComponent;
