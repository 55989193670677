import React from 'react';

import { Category } from '@/typings/entity/category';
import Heading from '@/components/Common/Heading';

interface Props {
  scrollTargetId: number | null;
  category: Category;
}

export default function ItemListHeader(props: Props) {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (props.scrollTargetId !== props.category.id) {
      return;
    }
    ref.current?.scrollIntoView();
  }, [props.scrollTargetId, props.category.id]);

  return (
    <div ref={ref}>
      <Heading>{props.category.name}</Heading>
    </div>
  );
}
