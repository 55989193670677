import React from 'react';
import { Label4 } from 'baseui/typography';

export default function Body() {
  return (
    <Label4>
      <p>
        株式会社Mobile Order
        Lab（以下「当社」といいます。）は、当社のサービスをご利用になる方（以下「ユーザー」といいます。）の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切に取り扱うものとします。
      </p>
      <p>&nbsp;</p>
      <p>1.　個人情報の定義</p>
      <p>
        本プライバシーポリシーにおいて、個人情報とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）を意味するものとします。
      </p>
      <p>&nbsp;</p>
      <p>2.　個人情報の利用目的</p>
      <p>当社は、ユーザーの個人情報を、以下の目的で利用いたします。</p>
      <p>(1)　当社のサービスの提供のため</p>
      <p>(2)　当社のサービスの利用に伴うご案内のため</p>
      <p>(3)　当社のサービスに関するご意見、お問い合せ等への対応のため</p>
      <p>(4)　当社の商品、サービス等のご案内のため</p>
      <p>
        (5)　当社のサービスに関する当社の規約、約款、ポリシー等（以下「規約等」といいます。）に違反する行為への対応のため
      </p>
      <p>(6)　当社のサービスの改善、新サービスの開発、マーケティング等のため</p>
      <p>(7)　ユーザーの同意に基づく当社のサービス利用企業等への個人情報の提供</p>
      <p>&nbsp;</p>
      <p>3.　個人情報の第三者への提供</p>
      <p>
        当社は、ユーザーの同意を得て、以下の提供先に、以下の提供情報内容を以下の手段又は方法で提供することがあります。
      </p>
      <p>(1)&nbsp; 提供先および目的について</p>
      <p>
        当社にモバイルオーダーの実施を委託した飲食店からユーザーへの連絡を可能にするため、当該飲食店にユーザーの個人情報を提供します。
      </p>
      <p>(2)　提供情報内容</p>
      <p>ユーザーから取得した情報</p>
      <p>(3)　提供の手段又は方法</p>
      <p>書面もしくは電磁的な方法による送付または送信</p>
      <p>&nbsp;</p>
      <p>
        また、次に掲げる場合は、関係法令に反しない範囲で、ユーザーの同意なく個人情報を第三者に提供することがあります。
      </p>
      <p>(1)　法令により許容されている場合</p>
      <p>(2)　ユーザーが第三者に不利益を及ぼすと判断した場合</p>
      <p>
        (3)　公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザー本人の承諾を得ることが困難である場合
      </p>
      <p>
        (4)　国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、ユーザー本人の同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合
      </p>
      <p>(5)　裁判所、検察庁、警察またはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合</p>
      <p>
        (6)　合併その他の事由による事業の承継に伴って個人情報が提供される場合であって、承継前の利用目的の範囲内で利用する場合
      </p>
      <p>&nbsp;</p>
      <p>4.　個人情報取扱業務の外部委託</p>
      <p>
        当社は、個人情報取扱業務の一部または全部を外部委託することがあります。なお、委託先における個人情報の取扱いについては当社が責任を負います。
      </p>
      <p>&nbsp;</p>
      <p>5.　統計データの利用</p>
      <p>
        当社は、個人を特定できないよう加工した統計データを作成することがあります。当社は、統計データを何ら制限なく利用することができるものとします。
      </p>
      <p>&nbsp;</p>
      <p>6.　個人情報の開示・訂正・利用停止等</p>
      <p>
        原則として、ユーザーご本人に限り、個人情報の開示、訂正、追加または削除、利用停止、ならびに第三者への提供の停止（以下「個人情報の開示等」といいます）を求めることができるものとします。ただし、以下の場合は個人情報の変更等に応じないことがあります。
      </p>
      <p>(1)　ユーザー本人または第三者の生命、身体、財産その他の権利・利益を害するおそれがある場合</p>
      <p>(2)　当社のサービスの適正な実施に著しい支障を及ぼすおそれがある場合</p>
      <p>(3)　他の法令に違反することとなる場合</p>
      <p>
        また、当該個人情報の変更等に多額の費用を要する場合､その他の、個人情報の開示等を行うことが困難な場合であって、ユーザーの権利・利益を保護するため必要なこれに代わるべき措置をとるときは、個人情報の変更等に応じないことがあります。
      </p>
      <p>&nbsp;</p>
      <p>7.　 Cookie（クッキー）その他の技術の利用</p>
      <p>
        当社サービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当社による当社サービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、当社サービスの一部の機能をご利用いただけなくなる場合があります。
      </p>
      <p>&nbsp;</p>
      <p>8.　お問い合わせ</p>
      <p>
        開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、以下の個人情報管理責任者までお願い致します。
      </p>
      <p>東京都渋谷区桜丘31&minus;14</p>
      <p>株式会社Mobile Order Lab　代表取締役</p>
      <p>電話番号：050-5240-9987</p>
      <p>（なお、受付時間は、平日10時から18時までとさせていただきます。）</p>
      <p>&nbsp;</p>
      <p>9.　プライバシーポリシーの変更</p>
      <p>
        当社は、法令等の定めがある場合を除き、必要に応じて、本プライバシーポリシーを変更することができるものとします。
      </p>
      <p>&nbsp;</p>
      <p>2020年4月1日　制定・施行</p>
      <p>&nbsp;</p>
    </Label4>
  );
}
