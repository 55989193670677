import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import cartSlice, { CartState } from './cart';
import restaurantSlice, { RestaurantState } from './restaurant';
import orderSlice, { OrderState } from './order';

export type AppState = {
  cart: CartState;
  order: OrderState;
  restaurant: RestaurantState;
};

const reducer = combineReducers({
  cart: cartSlice.reducer,
  order: orderSlice.reducer,
  restaurant: restaurantSlice.reducer,
});

const persistedReducer = persistReducer(
  {
    key: 'mobileorder-app',
    storage,
    whitelist: ['cart', 'order'],
  },
  reducer,
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: [thunk] as const,
});

export const persistor = persistStore(store);
