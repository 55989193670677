import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import Theme from '@/constants/Theme';

const engine = new Styletron();

const Base: React.FC = ({ children }) => {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={Theme}>{children}</BaseProvider>
    </StyletronProvider>
  );
};

export default Base;
