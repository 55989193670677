import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './App';
import * as serviceWorker from './serviceWorker';

const environment = process.env.REACT_APP_TARGET || 'local';

Sentry.init({ dsn: 'https://d7d2f3690757466aa2eea17c81e72ded@o398594.ingest.sentry.io/5256408', environment });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
