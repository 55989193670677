import * as React from 'react';
import { useStyletron } from 'baseui';
import { Label3, Paragraph2, Paragraph3, Paragraph4 } from 'baseui/typography';
import theme from '@/constants/Theme';
import { Block } from 'baseui/block';
import IconCall from '@/components/Svg/Call';
import Thumbnail from '@/components/Common/ImageAspectRatioFit';
import Map from '@/components/Common/Map';
import OpeningStatusLabel from '@/components/Common/OpeningStatusLabel';
import { Restaurant } from '@/typings/entity/restaurant';
import { headerImageUrl } from '@/helpers/restaurant';
import HorizontalScrolableNav from '@/components/Common/HorizontalScrolableNav';

interface Props {
  restaurant: Restaurant;
}

export default function RestaurantHeader(props: Props) {
  const [css, theme] = useStyletron();
  const headerImage = React.useMemo(() => headerImageUrl(props.restaurant), [props.restaurant]);
  const wrapper = React.useMemo(() => css(styles.wrapper), [css]);
  const headingWrapper = React.useMemo(() => css(styles.headingWrapper), [css]);
  const heading = React.useMemo(() => css(styles.heading), [css]);
  const detailWrapper = React.useMemo(() => css(styles.detailWrapper), [css]);
  const infoBlock = React.useMemo(() => css(styles.infoBlock), [css]);
  const spacer = React.useMemo(() => css(styles.infoBlock), [css]);
  const openingTimes = props.restaurant.openingTime
    ? props.restaurant.openingTime.map((t) => t.startAt + '〜' + t.endAt)
    : [];
  return (
    <div className={wrapper}>
      <Thumbnail src={headerImage} ratio='landscape' />
      <div className={headingWrapper}>
        <Block>
          <h1 className={heading}>
            {props.restaurant.brandName} {props.restaurant.name}
          </h1>
          <Paragraph4 paddingLeft='1px' color={theme.colors.primary500} $style={{ letterSpacing: '0.05em' }}>
            {props.restaurant.address}
          </Paragraph4>
        </Block>
        <Block paddingLeft='1rem'>
          <Map mapUrl={props.restaurant.mapUrl} />
        </Block>
      </div>
      <div className={detailWrapper}>
        <OpeningStatusLabel isOpen={props.restaurant.isOpen} />
        <Paragraph3 display='inline-block' color={theme.colors.primary400}>
          {openingTimes.join(',')}
        </Paragraph3>
        <Block>
          <Paragraph4 display='inline-block'>できあがり目安：</Paragraph4>
          <Label3 display='inline-block'>30分後</Label3>
        </Block>
      </div>
      <Block paddingTop='1rem'>
        <HorizontalScrolableNav>
          <div className={infoBlock}>
            <a href={'tel:' + props.restaurant.tel} style={{ textDecoration: 'none', color: theme.colors.primaryA }}>
              <Block display='flex' alignItems='center' padding='0.75rem 1rem'>
                <IconCall width='24px' height='24px' />
                <Block marginLeft='1rem'>
                  <Paragraph2 $style={{ textDecoration: 'underline' }}>{props.restaurant.tel}</Paragraph2>
                  <Paragraph4 color={theme.colors.primary400}>お電話でもテイクアウト注文可能</Paragraph4>
                </Block>
              </Block>
            </a>
          </div>
        </HorizontalScrolableNav>
      </Block>
      <div className={spacer}></div>
    </div>
  );
}

const styles = {
  wrapper: {
    width: '100%',
    maxWidth: theme.breakpoints.medium,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  headingWrapper: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '0.5rem',
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  detailWrapper: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  spacer: {
    width: '100%',
    height: '2rem',
  },
  heading: {
    fontSize: '21px',
    color: theme.colors.primary,
    marginTop: 0,
    marginBottom: 0,
  },
  infoBlock: {
    display: 'inline-block',
    border: 'solid 1px #ddd',
    borderRadius: '0.25rem',
  },
};
