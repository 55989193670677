import React from 'react';
import { useStyletron, ThemeProvider } from 'baseui';
import Theme from '@/constants/Theme';
import Thumbnail from '@/components/Common/ImageAspectRatioFit';
// import IconTimer from '@/components/Svg/Timer';
import { formatPrice } from '@/helpers/format';
import { Theme as BaseUiTheme } from 'baseui/theme';
import { Item } from '@/typings/entity/item';
import { itemImageUrl } from '@/helpers/item';
import { Restaurant } from '@/typings/entity/restaurant';
// import { Block } from 'baseui/block';
// import IconPlus from "@/components/Svg/Plus";

interface Props {
  item: Item;
  restaurant: Restaurant;
  size?: 'large' | undefined;
}

export default function MenuItem(props: Props) {
  const [css, theme] = useStyletron();

  const card = styles.card;
  const imageUrl = itemImageUrl(props.item, props.restaurant);
  const heading = React.useMemo(() => styles.heading(props, theme), [props, theme]);
  const footer = React.useMemo(() => styles.footWrapper(props), [props]);
  const container = React.useMemo(() => styles.container(props), [props]);
  const price = React.useMemo(() => styles.price(props), [props]);
  // const timer = React.useMemo(() => styles.timer(props, theme), [props, theme]);
  return (
    <ThemeProvider theme={Theme}>
      <div className={css(card)}>
        <Thumbnail src={imageUrl} radius={props.size !== 'large'} ratio='square' />
        <div className={css(container)}>
          <p className={css(heading)}>{props.item.name}</p>
          <div className={css(footer)}>
            <p className={css(price)}>{formatPrice(props.item.unitPrice)}</p>
            {/*<time className={css(timer)}>*/}
            {/*  <IconTimer width='1em' height='1em' />*/}
            {/*  {props.item.cookingTime}分*/}
            {/*</time>*/}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

const styles = {
  card: {
    width: '100%',
  },
  footWrapper: (props: Props) => ({
    display: 'flex',
    justifyContent: props.size === 'large' ? 'start' : 'space-between',
  }),
  container: (props: Props) => ({
    padding: props.size === 'large' ? '0.5rem 1rem' : '2px 4px',
  }),
  heading: (props: Props, theme: BaseUiTheme) => ({
    display: '-webkit-box',
    fontSize: props.size === 'large' ? '1.25rem' : '13px',
    color: theme.colors.primaryA,
    lineHeight: '1.55',
    overflow: 'hidden',
    marginTop: props.size === 'large' ? '1rem' : '0.25rem',
    marginBottom: '0.25rem',
    marginRight: props.size === 'large' ? '3em' : 0,
    'white-space': 'pre-wrap',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': props.size === 'large' ? 'none' : 2,
  }),
  timer: (props: Props, theme: BaseUiTheme) => ({
    fontSize: props.size === 'large' ? '1rem' : '13px',
    color: theme.colors.primary400,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.5rem',
    marginTop: '0.2em',
  }),
  price: (props: Props) => ({
    fontSize: props.size === 'large' ? '1.5rem' : '18px',
    marginBottom: 0,
    marginLeft: '1px',
    ':after': {
      content: "'円'",
      display: 'inline-block',
      marginLeft: '1px',
      fontSize: props.size === 'large' ? '1rem' : '13px',
      fontWeight: 700,
    },
  }),
};
