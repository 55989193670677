import * as React from 'react';
import { useStyletron } from 'baseui';
import { StyleObject } from 'styletron-react';
import { Theme } from 'baseui/theme';

type Size = 'large' | 'medium' | 'small';

interface Props {
  children: React.ReactNode;
  size?: Size;
}

const Wrapper: React.FC<Props> = ({ children, size }) => {
  const [css, theme] = useStyletron();
  const maxWidth = getMaxWidth(theme, size);
  const Wrapper = React.useMemo(() => styles.wrapper(maxWidth), [maxWidth]);
  return <div className={css(Wrapper)}>{children}</div>;
};

function getMaxWidth(theme: Theme, size?: Size): number {
  if (!size) {
    return theme.breakpoints.large;
  }
  if (size === 'medium') {
    return theme.breakpoints.medium;
  }
  if (size === 'small') {
    return theme.breakpoints.small;
  }
  return theme.breakpoints.large;
}

const styles = {
  wrapper: (maxWidth: number): StyleObject => ({
    maxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
};

export default Wrapper;
