import React, { CSSProperties } from 'react';
import { useStyletron } from 'baseui';
import { Theme } from 'baseui/theme';
import { StyleObject } from 'styletron-react';

interface Props {
  style?: CSSProperties;
}

const HoverEffect: React.FC<Props> = ({ children, style }) => {
  const [css, theme] = useStyletron();
  return (
    <div className={css(styles.hover(theme))} style={{ ...style }}>
      {children}
    </div>
  );
};

const styles = {
  hover: (theme: Theme): StyleObject => ({
    display: 'inline-block',
    ':hover': {
      position: 'relative',
      cursor: 'pointer',
    },
    ':after': {
      content: "''",
      opacity: 0,
    },
    ':hover:after': {
      content: "''",
      opacity: 1,
      width: 'calc(100% + 0.8rem)',
      height: 'calc(100% + 0.8rem)',
      position: 'absolute',
      left: '-0.4rem',
      top: '-0.4rem',
      pointerEvents: 'none',
      cursor: 'pointer',
      transitionProperty: 'opacity',
      transitionDuration: theme.animation.timing400,
      transitionTimingFunction: theme.animation.easeInOutCurve,
      borderRadius: theme.borders.radius400,
      backgroundColor: theme.colors.borderTransparent,
    },
  }),
};

export default HoverEffect;
