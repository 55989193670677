import React from 'react';
import Lottie from 'react-lottie';
import animationData from '@/components/Cart/heart-in-hand.json';

// TODO! resolve warning https://github.com/JedWatson/react-select/issues/3715

interface Props {
  width?: number;
  height?: number;
  options?: any;
}

export default function LottieAnimation(props: Props) {
  return <Lottie options={{ ...defaultOptions, ...props.options }} height={props.height} width={props.width} />;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
