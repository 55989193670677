import React from 'react';
import { styled } from 'baseui';
import { Block } from 'baseui/block';
import Thumbnail from '@/components/Common/ImageAspectRatioFit';
import { Label2 } from 'baseui/typography';
import { formatPrice } from '@/helpers/format';
import ItemCounter from '../../Common/ItemCounter';
import { CartItem as CartItemEntity } from '@/typings/entity/cart_item';
import { Item } from '@/typings/entity/item';
import { itemImageUrl } from '@/helpers/item';
import { useDispatch } from 'react-redux';
import cartSlice from '@/stores/cart';
import { Restaurant } from '@/typings/entity/restaurant';

interface Props {
  cartItem: CartItemEntity;
  items: Item[];
  restaurant: Restaurant;
  removeCartItem: (id: number) => void;
}

export default function CartItem(props: Props) {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = React.useState<number>(props.cartItem.quantity);
  const item = React.useMemo(() => props.items.find((i) => i.id === props.cartItem.itemId), [
    props.cartItem.itemId,
    props.items,
  ]);
  const isSoldout = React.useMemo(() => item && item.status !== 1, [item]);
  const updateQuantity = (quantity: number) => {
    setQuantity(quantity);
    dispatch(
      cartSlice.actions.update({
        itemId: props.cartItem.itemId,
        quantity,
      }),
    );
  };
  const increment = () => updateQuantity(quantity + 1);
  const decrement = () => {
    if (quantity <= 1) {
      props.removeCartItem(props.cartItem.itemId);
      dispatch(cartSlice.actions.remove(props.cartItem.itemId));
      return;
    }
    updateQuantity(quantity - 1);
  };
  if (!item) {
    return null;
  }
  const Container = isSoldout ? SoldoutWrapper : Wrapper;
  return (
    <Container>
      <ItemWrapper>
        <Block minWidth='5rem' width='5rem' marginRight='0.5rem'>
          <Thumbnail src={itemImageUrl(item, props.restaurant)} ratio='square' />
        </Block>
        <Block>
          <ItemName>{item.name}</ItemName>
          {!isSoldout && <Label2 marginTop='0.5rem'>{formatPrice(item.unitPrice * quantity)}円</Label2>}
        </Block>
      </ItemWrapper>
      <Block>
        {isSoldout && <Soldout>品切れのためカートから削除されます</Soldout>}
        {!isSoldout && <ItemCounter count={quantity} increment={increment} decrement={decrement} />}
      </Block>
    </Container>
  );
}

const Soldout = styled('div', () => ({
  padding: '4px',
  fontSize: '11px',
  color: '#999',
  textAlign: 'center',
}));

const ItemWrapper = styled('div', () => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const SoldoutWrapper = styled('div', ({ $theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: 'solid 1px ' + $theme.colors.backgroundLightAccent,
  ':last-child': {
    borderBottom: 'solid 1px ' + $theme.colors.backgroundLightAccent,
  },
  ':after': {
    content: "''",
    opacity: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    cursor: 'pointer',
    transitionProperty: 'opacity',
    transitionDuration: $theme.animation.timing400,
    transitionTimingFunction: $theme.animation.easeInOutCurve,
    backgroundColor: $theme.colors.borderTransparent,
  },
}));

const Wrapper = styled('div', ({ $theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.5rem 1rem',
  borderTop: 'solid 1px ' + $theme.colors.backgroundLightAccent,
  ':last-child': {
    borderBottom: 'solid 1px ' + $theme.colors.backgroundLightAccent,
  },
}));

const ItemName = styled('div', ({ $theme }) => ({
  display: '-webkit-box',
  maxHeight: '3em',
  height: 'auto',
  fontSize: '12px',
  color: $theme.colors.primary,
  lineHeight: '1.5',
  overflow: 'hidden',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': 2,
}));
