import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 94 100" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91 0H75v4H0v94h3v2h12v-2h64v2h12v-2h3V4h-3V0zm0 7H3v88h88V7zM76 18H20v30h56V18zM28 30a4 4 0 100-8 4 4 0 000 8zm-8 20h56v30H20V50zm12 8a4 4 0 11-8 0 4 4 0 018 0zM16 14h64v3H16v-3z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
