import { AppRoute } from '@/typings/route';

import Lp from '@/pages/Lp';
import LpRegister from '@/pages/Lp/register';
import Top from '@/pages/Top';
import Item from '@/pages/Item';
import Cart from '@/pages/Cart';
import Register from '@/pages/Register';
import Complete from '@/pages/Complete';
import Terms from '@/pages/Terms';
import NotFound from './pages/NotFound';

export const routes: AppRoute[] = [
  {
    name: 'lp',
    exact: true,
    path: '/',
    component: Lp,
  },
  {
    name: 'register',
    exact: true,
    path: '/register',
    component: LpRegister,
  },
  {
    name: 'top',
    exact: true,
    path: '/:brandSlug/:restaurantId',
    component: Top,
  },
  {
    name: 'item',
    exact: true,
    path: '/:brandSlug/:restaurantId/item/:id',
    component: Item,
  },
  {
    name: 'cart',
    exact: true,
    path: '/:brandSlug/:restaurantId/cart',
    component: Cart,
  },
  {
    name: 'register',
    exact: true,
    path: '/:brandSlug/:restaurantId/cart/register',
    component: Register,
  },
  {
    name: 'complete',
    exact: true,
    path: '/:brandSlug/:restaurantId/cart/complete',
    component: Complete,
  },
  {
    name: 'terms',
    exact: true,
    path: '/terms',
    component: Terms,
  },
  {
    name: 'notFound',
    exact: false,
    path: undefined,
    component: NotFound,
  },
];
