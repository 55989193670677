import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
}

export default function PageTitle(props: Props) {
  return (
    <>
      <Helmet>
        <title>{props.title} | Mobile Order</title>
      </Helmet>
    </>
  );
}
