import React from 'react';
import { ThemeProvider } from 'emotion-theming'
import { Box, Heading, Link } from 'rebass';
import theme from '@/components/Lp/Theme'
import Lottie from 'react-lottie';
import LottieAnimationOrders from '@/components/Lp/LottieOrders.json';

const RegisterURL = 'https://docs.google.com/forms/d/e/1FAIpQLScP4Ua2lf4_xq-xPldaKOxXAzpYXb5DGnnLgtYG2WckTVzfuw/viewform'
const lottieOptionOrders: any = {
  loop: true,
  autoplay: true,
  animationData: LottieAnimationOrders,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export default function LpRegister() {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = RegisterURL;
    }, 1000);
    return () => clearTimeout(timer);
  },[]);
  return (
  <>
    <ThemeProvider theme={theme}>
      <Box variant='body' sx={{textAlign:'center',pt:6,backgroundColor:'#fff'}}>
        <Box sx={{mx:'auto',maxWidth:['90vw',null,null]}}>
          <Heading as='h2' variant='title' my={1} mx={3}>資料請求ページへ移動中...</Heading>
          <Link href={RegisterURL} sx={{display:'block'}}>自動で移動しない場合はこちらをクリック</Link>
        </Box>
        <Box maxWidth={[300, null, 540]} my={5} mx='auto'>
          <Lottie options={lottieOptionOrders}/>
        </Box>
      </Box>
    </ThemeProvider>
  </>
)}