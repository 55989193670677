import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import { CartItem } from '@/typings/entity/cart_item';

export interface CartState {
  pristine: boolean;
  receiveAt: string;
  list: CartItem[];
}

const initialState: CartState = {
  pristine: true,
  receiveAt: '',
  list: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    initialize(state) {
      state.pristine = false;
      state.receiveAt = '';
      state.list = [];
    },
    flush(state) {
      state.receiveAt = '';
      state.list = [];
    },
    add(state, value: PayloadAction<CartItem>) {
      const added = state.list.find((ci) => ci.itemId === value.payload.itemId);
      if (!added) {
        state.list.push(value.payload);
        return;
      }
      state.list = state.list.map((ci) => {
        if (ci.itemId !== value.payload.itemId) {
          return ci;
        }
        return {
          itemId: ci.itemId,
          quantity: ci.quantity + value.payload.quantity,
        };
      });
    },
    update(state, value: PayloadAction<CartItem>) {
      state.list = state.list.map((ci) => {
        if (ci.itemId !== value.payload.itemId) {
          return ci;
        }
        return value.payload;
      });
    },
    remove(state, value: PayloadAction<number>) {
      state.list = state.list.filter((ci) => ci.itemId !== value.payload);
    },
    setReceivedAt(state, value: PayloadAction<string>) {
      state.receiveAt = value.payload;
    },
  },
});

export const useCart = () => {
  return useSelector((state: AppState) => state.cart);
};

export default cartSlice;
