import React from 'react';
import { SoftwareApplication } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
const meta = {
  title: '注文管理サービスOrdee（オーディー）',
  description:
    'UberEatsなどデリバリー・テイクアウト注文を一元管理して店内オペレーションを効率化。withコロナで高まるデリバリー需要に合わせて、デリバリーサービスの導入を後押しします。',
  image: 'https://ordee.io/images/lp/ogp.png',
  url: 'https://ordee.io/',
  serviceName: 'Ordee',
  alternateServiceName: 'オーディー',
  applicationCategory: 'SaaS',
  operatingSystem: 'iPadOS, Windows, OSX',
  fbAppId: '745822472682467',
  twitterCardType: 'summary_large_image',
};
export default function Seo() {
  return (
    <Helmet
      script={[
        helmetJsonLdProp<SoftwareApplication>({
          '@context': 'https://schema.org',
          '@type': 'SoftwareApplication',
          name: `${meta.serviceName}`,
          alternateName: `${meta.alternateServiceName}`,
          description: `${meta.description}`,
          image: `${meta.image}`,
          url: `${meta.url}`,
          sameAs: `${meta.url}`,
          applicationCategory: 'SaaS',
          operatingSystem: 'iPadOS, Windows, OSX',
        }),
      ]}
    >
      <title>{meta.title}</title>
      <html lang='ja' />
      <meta name='description' content={meta.description} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={meta.title} />
      <meta property='og:description' content={meta.description} />
      <meta property='og:image' content={meta.image} />
      <meta property='og:image:alt' content={meta.title} />
      <meta property='og:url' content={meta.url} />
      <meta property='og:site_name' content={meta.title} />
      <meta property='fb:app_id' content={meta.fbAppId} />
      <meta name='twitter:card' content={meta.twitterCardType} />
      <meta name='twitter:url' content={meta.url} />
      <meta name='twitter:title' content={meta.title} />
      <meta name='twitter:description' content={meta.description} />
      <meta name='twitter:image:src' content={meta.image} />
      <meta name='twitter:site' content={meta.title} />
      <link rel='canonical' href={meta.url} />
      <link rel='icon' href='/favicon.svg' type='image/svg+xml' />
      <link
        href='https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap'
        rel='stylesheet'
      ></link>
      {/* <!-- Start of HubSpot Embed Code --> */}
      <script type='text/javascript' id='hs-script-loader' async defer src='//js.hs-scripts.com/7997725.js'></script>
      {/* <!-- End of HubSpot Embed Code --> */}
    </Helmet>
  );
}
