import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 135 108" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132 3v90H3V3h129zm3-3v96H94v8h34v4H8v-4h34v-8H0V0h135zM55 7v16H6V7h49zm-37 8a4 4 0 11-8 0 4 4 0 018 0zm37 10v16H6V25h49zm-37 8a4 4 0 11-8 0 4 4 0 018 0zm37 26V43H6v16h49zm-41-4a4 4 0 100-8 4 4 0 000 8zM7 82h18v8H7v-8zm48 0H27v8h28v-8zm4-75h22v8H59V7zm22 10H59v8h22v-8zM59 27h22v8H59v-8zm22 10H59v15h22V37zM59 54h22v17H59V54zm22 19H59v17h22V73zm2-66h22v8H83V7zm22 10H83v8h22v-8zM83 27h22v8H83v-8zm22 10H83v15h22V37zM83 54h22v17H83V54zm22 19H83v17h22V73zm2-66h22v8h-22V7zm22 10h-22v8h22v-8zm-22 10h22v8h-22v-8zm22 10h-22v15h22V37zm-22 17h22v17h-22V54zm22 19h-22v17h22V73z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent