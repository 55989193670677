import React from 'react';
import { useStyletron } from 'baseui';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { Theme } from 'baseui/theme';
import { StyleObject } from 'styletron-react';

interface Props {
  src: string;
  radius?: boolean;
  ratio?: 'square' | 'landscape';
}

export default function MenuThumbnail(props: Props) {
  const [css, theme] = useStyletron();
  return (
    <>
      <AspectRatioBox aspectRatio={props.ratio === 'square' ? 5 / 4 : 10 / 3}>
        <AspectRatioBoxBody display='flex' alignItems='center' justifyContent='center'>
          <div className={css(styles.imageWrapper(props, theme))} />
        </AspectRatioBoxBody>
      </AspectRatioBox>
    </>
  );
}

const styles = {
  imageWrapper: (props: Props, theme: Theme): StyleObject => ({
    width: '100%',
    height: 0,
    paddingBottom: props.ratio === 'square' ? 'calc( 4 / 5 * 100%)' : 'calc( 3 / 10 * 100%)',
    borderRadius: props.radius ? theme.borders.radius300 : 0,
    backgroundColor: '#ddd',
    backgroundImage: 'url(' + props.src + '), url(/images/items/dummy.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
  }),
};
