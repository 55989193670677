import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 4h16v2H4V4zm14.96 8l-.6-3H5.64l-.6 3h13.92zM20 7H4l-1 5v2h1v6h10v-6h4v6h2v-6h1v-2l-1-5zM6 14v4h6v-4H6z'
        fill='CurrentColor'
      />
    </svg>
  );
}

export default SvgComponent;
