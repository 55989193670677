export default {
    breakpoints: ['40em', '52em', '64em'],
    fontSizes: [
      // 11, 15, 18, 28, 38, 126
      '0.7rem', '0.94rem', '1.13rem', '1.75rem', '2.375rem', '7.8rem'
    ],
    colors: {
      background: '#F3F3F3',
      primary: '#000',
      text: '#0a0a0a',
      white: '#fff',
      green:'#16B439',
      red: '#E31010',
      orange:'#EA5D26',
    },
    space: [
      // 0, 4, 8, 16, 32, 64, 128, 256
      0, '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '8rem', '16rem'
    ],
    fonts: {
      body: 'Noto Sans JP, sans-serif',
      heading: 'roboto, noto sans JP',
      monospace: 'roboto, monospace',
    },
    fontWeights: {
      body: 400,
      bold: 700,
      heading: 700,
    },
    lineHeights: {
      body: 1.8,
      heading: 1.4,
    },
    letterSpacings: {
      body: '0.06rem',
      heading: '-0.03rem',
    },
    shadows: {
      small: '0 0 4px rgba(0, 0, 0, .125)',
      large: '0 0 24px rgba(0, 0, 0, .125)'
    },
    variants: {
      body: {
        color:'text',
        backgroundColor:'background',
        fontSize: ['13px',1,1],
        lineHeight: 'body',
        letterSpacing: 'body',
        '[href=*]': {
          color: 'black',
          ':hover': {
            color: 'black',
            opacity: 0.8
          }
        }
      },
      border: {
        separate: {
          borderStyle: 'solid',
          borderWidth: '2px',
          borderColor: 'black'
        }
      },
      undelineLink: {
        color:'black',
        display: 'inline-block',
        position: 'relative',
        ':before': {
          content: '""',
          position: 'absolute',
          bottom: '100%',
          display: 'block',
          width: '100%',
          height:'1px',
          backgroundColor: 'black',
          opacity: 0,
        },
        ':hover': {
          color: 'inherit',
          textDecoration: 'none',
          opacity: '.9'
        },
        ':hover:before': {
          content: '""',
          position: 'absolute',
          bottom: '0',
          display: 'block',
          width: '100%',
          height:'1px',
          backgroundColor: 'black',
          transition: '0.2s',
          opacity: 1,
        }
      },
      undelineLinkWhite: {
        color:'white',
        display: 'inline-block',
        position: 'relative',
        ':before': {
          content: '""',
          position: 'absolute',
          bottom: '100%',
          display: 'block',
          width: '100%',
          height:'1px',
          backgroundColor: 'white',
          opacity: 0,
        },
        ':hover': {
          color: 'inherit',
          textDecoration: 'none',
          opacity: '.9'
        },
        ':hover:before': {
          content: '""',
          position: 'absolute',
          bottom: '0',
          display: 'block',
          width: '100%',
          height:'1px',
          backgroundColor: 'white',
          transition: '0.2s',
          opacity: 1,
        } 
      }
    },
    text: {
      title: {
        display: 'inline-block',
        fontSize: ['7vw',4,4],
        letterSpacing: 'heading',
      },
      h2: {
        fonts: 'heading',
        lineHeight: 'body',
        letterSpacing: 'heading',
        fontSize: ['1.4rem',3,3],
      },
      h3: {
        fonts: 'heading',
        lineHeight: 'body',
        letterSpacing: 'body',
        fontSize: ['1rem',2,2],
      },
    },
    buttons: {
      primary: {
        color: 'white',
        bg: 'green',
        fontSize: [2,2,2],
        fontWeight: 'bold',
        letterSpacing: 'body',
        borderRadius: '1.5em',
        px: 4,
        transition: '0.1s',
        ':hover': {
          backgroundColor: 'green',
          filter: 'brightness(90%)',
          transform: 'translateY(2px)'
        }
      },
    }
  }