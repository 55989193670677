import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export function useInputState(defaultValue: string = '') {
  const [value, setValue] = useState<string>(defaultValue);
  const handleChange = (e: any) => setValue(e.currentTarget.value);
  return {
    value,
    handleChange,
  };
}

interface BaseRouteParams {
  brandSlug: string;
  restaurantId: string;
}
export function useBaseRouteParams() {
  const params = useParams<BaseRouteParams>();
  const restaurantId = parseInt(params.restaurantId, 10);
  const brandSlug = params.brandSlug;
  return useMemo(
    () => ({
      restaurantId,
      brandSlug,
    }),
    [restaurantId, brandSlug],
  );
}

export function useInternalLink(path: string = '') {
  const { brandSlug, restaurantId } = useBaseRouteParams();
  return useMemo(() => `/${brandSlug}/${restaurantId}${path}`, [brandSlug, restaurantId, path]);
}
