import React from 'react';
import { Link } from 'react-router-dom';

import { Item } from '@/typings/entity/item';

import Hover from '@/components/Common/Hover';
import MenuItem from '@/components/Common/MenuItem';
import theme from '@/constants/Theme';
import { useInternalLink } from '@/helpers/hooks';
import { Restaurant } from '@/typings/entity/restaurant';

interface Props {
  item: Item;
  restaurant: Restaurant;
}

export default function ListItem(props: Props) {
  const link = useInternalLink(`/item/${props.item.id}`);
  return (
    <Link to={link} style={styles.link}>
      <Hover style={styles.hover}>
        <MenuItem item={props.item} restaurant={props.restaurant} />
      </Hover>
    </Link>
  );
}

const styles = {
  link: {
    maxWidth: '180px',
    color: theme.colors.primaryA,
  },
  hover: {
    minWidth: '100%',
  },
};
