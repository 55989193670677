import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '@/stores/cart';
import { useStyletron, ThemeProvider } from 'baseui';
import { Block } from 'baseui/block';
import { Client } from 'styletron-engine-atomic';
import Theme from '@/constants/Theme';
import Hover from '@/components/Common/Hover';
import IconCart from '@/components/Svg/Cart';
import SvgLogo from '@/components/Svg/Logo';
import { useInternalLink } from '@/helpers/hooks';

export default function Header() {
  const cart = useCart();
  const [css] = useStyletron();
  const linkToTop = useInternalLink();
  const linkToCart = useInternalLink('/cart');
  const hasAny = React.useMemo(() => cart.list.length > 0, [cart.list]);
  const wrapper = React.useMemo(() => css(styles.wrapper), [css]);
  const logo = React.useMemo(() => css(styles.logo), [css]);
  const link = React.useMemo(() => css(styles.link), [css]);
  const rightWrapper = React.useMemo(() => css(styles.rightWrapper), [css]);
  const action = React.useMemo(() => css(styles.action), [css]);
  const cartCounter = React.useMemo(() => css(styles.cartCounter), [css]);
  return React.useMemo(
    () => (
      <ThemeProvider theme={Theme}>
        <div className={wrapper}>
          <div className={logo}>
            <Link to={linkToTop} className={link}>
              <SvgLogo title='テイクアウト' width='100px' height='24px' />
            </Link>
          </div>
          <div className={rightWrapper}>
            <div className={action}>
              <Hover>
                <Link to={linkToCart} className={link}>
                  <Block position='relative'>
                    <IconCart width='24px' height='24px' />
                    {hasAny && <span className={cartCounter} />}
                  </Block>
                </Link>
              </Hover>
            </div>
          </div>
        </div>
      </ThemeProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasAny, linkToTop, linkToCart],
  );
}

const instance = new Client();
const flashing = instance.renderKeyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const styles = {
  wrapper: {
    width: '100%',
    height: '3.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    maxWidth: Theme.breakpoints.large,
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: Theme.lighting.shadow400,
    color: Theme.colors.primary,
  },
  rightWrapper: {
    display: 'flex',
  },
  action: {
    width: '3rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  logo: {
    width: '8rem',
  },
  link: {
    color: Theme.colors.primaryA,
  },
  cartCounter: {
    display: 'flex',
    width: '12px',
    height: '12px',
    borderRadius: '100%',
    background: Theme.colors.accent,
    position: 'absolute' as const,
    top: '-1px',
    right: '-4px',
    animationDuration: '1s',
    animationTimingFunction: Theme.animation.easeOutCurve,
    animationIterationCount: 'infinite',
    animationName: flashing,
  },
};
