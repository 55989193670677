import React from 'react';
import { Flex, Box, Text, Link } from 'rebass';

interface Props {
  date: string;
  url: string;
  text: string;
}

export default function NewsLink(props: Props) {
  return (
    <Box mt={3}>
      <Flex flexDirection={['column', 'row', 'row']}>
        <Text mr={2} color='gray' sx={{ maxWidth: 90, minWidth: 90 }}>
          {props.date}
        </Text>
        <Link color='text' href={props.url} target='_blank'>
          <Text>{props.text}</Text>
        </Link>
      </Flex>
    </Box>
  );
}
