import React from 'react';
import { Plus, CheckIndeterminate } from 'baseui/icon';
import { styled } from 'baseui';
import Hover from './Hover';

interface Props {
  count: number;
  increment: any;
  decrement: any;
  size?: 'small' | 'large';
  deletable?: boolean;
}

export default function ItemCounter(props: Props) {
  const Wrapper = React.useMemo(() => makeWrapper(props), [props]);
  const iconSize = props.size === 'large' ? sizeStyle.icon.large : sizeStyle.icon.small;

  return (
    <Wrapper>
      <Hover>
        <CounterIcon onClick={props.decrement}>
          <CheckIndeterminate size={iconSize} />
        </CounterIcon>
      </Hover>
      <CountNumber>{props.count}</CountNumber>
      <Hover>
        <CounterIcon onClick={props.increment}>
          <Plus size={iconSize} />
        </CounterIcon>
      </Hover>
    </Wrapper>
  );
}

const makeWrapper = (props: Props) =>
  styled('div', () => ({
    maxWidth: '100%',
    width: props.size === 'large' ? sizeStyle.width.large : sizeStyle.width.small,
    fontSize: props.size === 'large' ? sizeStyle.fontSize.large : sizeStyle.fontSize.small,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  }));

const CounterIcon = styled('div', ({ $theme }) => ({
  backgroundColor: $theme.colors.warning200,
  borderRadius: '100%',
}));
const CountNumber = styled('p', () => ({
  display: 'flex',
  width: '2em',
  justifyContent: 'center',
}));
const sizeStyle = {
  icon: {
    small: '1.5rem',
    large: '3rem',
  },
  width: {
    small: '6rem',
    large: '12rem',
  },
  fontSize: {
    small: '14px',
    large: '18px',
  },
};
