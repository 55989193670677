import React from 'react';
import ReactGA from 'react-ga';
import { Route, RouteProps } from 'react-router-dom';
import useRouter from 'use-react-router';

const prdTrackingCode = 'UA-168187806-1';
const stgTrackingCode = 'UA-168187806-2';
const trackingCode = process.env.REACT_APP_TARGET === 'production' ? prdTrackingCode : stgTrackingCode;

type Props = RouteProps & {
  key: string;
};

const GaListener = (props: Props) => {
  const router = useRouter();
  React.useEffect(() => {
    ReactGA.initialize(trackingCode);

    const page = router.location.pathname;
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }, [router.location.pathname]);

  return <Route {...props} />;
};

export default GaListener;
