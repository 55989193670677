import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgLogo({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg width={38} height={7} viewBox='0 0 38 7' fill='none' aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M1.25 1.132v.82h3.94v-.82H1.25zm2.58 2.38h2.082v-.82H.53v.82H2.9c-.038 1.063-.31 1.63-1.605 2.172l.587.615c.061.063.157.08.235.038C3.463 5.61 3.78 4.742 3.83 3.511zM10.696 2.815c.67-.496 1.27-1.025 1.696-1.509l-.729-.573c-.8 1.012-2.907 2.475-4.371 2.94l.49.818c.612-.245 1.29-.606 1.947-1.018v2.895h.767a.2.2 0 00.2-.2V2.815zM16.041 1.687c.123-.226.239-.452.329-.664l-.858-.31c-.367.935-1.218 2.192-2.16 2.895l.697.567c.516-.419 1.044-1.038 1.496-1.689h1.747c-.29 1.303-1.09 2.528-2.934 3.172l.518.627c.056.068.15.092.232.056 1.805-.798 2.75-2.078 3.274-4.187a.198.198 0 00-.108-.227l-.471-.221a.2.2 0 00-.085-.02H16.04zM20.013 1.164v.839h4.003c-.225.374-.676.928-1.09 1.334l.781.503a18.646 18.646 0 001.56-2.055.198.198 0 00-.057-.271l-.466-.315a.2.2 0 00-.112-.035h-4.62zm1.889 1.206c0 1.87-.045 2.534-1.438 3.353l.606.546c.068.062.17.069.245.016 1.393-.993 1.502-1.95 1.502-3.915h-.915zM26.821 1.79v2.16h.928V2.64h2.908c-.096 1.265-.722 2.38-2.52 2.96l.473.675a.196.196 0 00.23.07c1.902-.74 2.666-2.2 2.867-4.087a.198.198 0 00-.12-.202l-.596-.25a.2.2 0 00-.078-.016H29.6V.733h-.935V1.79h-1.844zM34.584 2.48V.733h-.942v5.635h.742a.2.2 0 00.2-.2V3.473c.773.38 1.586.857 2.218 1.34l.606-.773a20.632 20.632 0 00-2.824-1.56z'
        fill='currentColor'
      />
    </svg>
  );
}

export default SvgLogo;
