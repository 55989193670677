import React from 'react';
import { useStyletron } from 'baseui';
import { StyledLink } from 'baseui/link';
// import { Paragraph4 } from 'baseui/typography';
import Hover from '../Common/Hover';
import IconMap from '../Svg/Map';

interface Props {
  mapUrl: string;
}

export default function PageTitle(props: Props) {
  const [css] = useStyletron();
  const iconStyle = React.useMemo(() => css(styles.iconStyle), [css]);
  return (
    <Hover>
      <StyledLink href={props.mapUrl}>
        <div className={iconStyle}>
          <IconMap width='32px' height='32px' />
          {/* <Paragraph4 display='flex' justifyContent='center'>
            地図
          </Paragraph4> */}
        </div>
      </StyledLink>
    </Hover>
  );
}

const styles = {
  iconStyle: {
    fontSize: '11px',
    display: 'block',
    padding: '.5rem',
    color: '#000',
  },
};
