// const containerSize = 980
const containerSize = '64em'
const gutterSize = 3

export default {
  view: {
    fill: '100%',
    max: containerSize,
    min: 300,
    gutter: gutterSize,
  },
  container: {
    maxWidth: ['100%', '100%', containerSize],
    mx: 'auto',
    px: [gutterSize, null, null]
  }
}