import React from 'react';
import { styled } from 'baseui';
import { Button, ButtonProps, SIZE, SHAPE, KIND } from 'baseui/button';
import { Block, BlockProps } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Label1, Label3, Label4, Paragraph3, Paragraph4 } from 'baseui/typography';
import theme from '@/constants/Theme';
import { CartItem as CartItemEntity } from '@/typings/entity/cart_item';
import { Item } from '@/typings/entity/item';
import Map from '@/components/Common/Map';
import CartItem from '@/components/Cart/CartContents/CartItem';
import { useInternalLink } from '@/helpers/hooks';
import { Restaurant } from '@/typings/entity/restaurant';
import useRouter from 'use-react-router';
import { formatPrice } from '@/helpers/format';
import cartSlice from '@/stores/cart';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

interface Props {
  restaurant: Restaurant;
  cartItems: CartItemEntity[];
  setCartItems: (cartItems: CartItemEntity[]) => void;
  receivedAt: string;
  totalPrice: number;
}

interface SelectableTime {
  label: string;
  value: string;
}

interface ActiveRange {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
}

export default function CartContents(props: Props) {
  const { history } = useRouter();
  const linkToTop = useInternalLink();
  const linkToRegister = useInternalLink('/cart/register');
  const dispatch = useDispatch();
  const items = React.useMemo(() => collectItems(props.restaurant), [props.restaurant]);
  const [defaultReceivedAt, setDefaultReceivedAt] = React.useState<string | undefined>();
  const [receivedAt, setReceivedAt] = React.useState<string | undefined>(props.receivedAt || defaultReceivedAt);
  const [selectableTimes, setSelectableTimes] = React.useState<SelectableTime[]>([]);

  React.useEffect(() => {
    if (!props.restaurant.openingTime) {
      return;
    }
    setSelectableTimes(makeSelectableTimes(props.restaurant));
  }, [props.restaurant]);

  React.useEffect(() => {
    if (selectableTimes.length === 0) {
      return;
    }
    const [defaultSelected] = selectableTimes;
    const { value } = defaultSelected;
    setDefaultReceivedAt(value);
    setReceivedAt(value);
    dispatch(cartSlice.actions.setReceivedAt(value));
  }, [selectableTimes, dispatch]);

  const addReceivedAt = (value: string) => {
    setReceivedAt(value);
    dispatch(cartSlice.actions.setReceivedAt(value));
  };
  const handleClickGoTop = React.useCallback(() => history.push(linkToTop), [history, linkToTop]);
  const handleClickGoRegister = React.useCallback(() => history.push(linkToRegister), [history, linkToRegister]);
  const removeCartItem = (id: number) => {
    props.setCartItems(props.cartItems.filter((ci) => ci.itemId !== id));
  };
  console.log({ selectableTimes })
  return (
    <>
      {props.cartItems.length === 0 && (
        <>
          <Block margin='2rem' display='flex' justifyContent='center'>
            <img
              src='/images/empty.png'
              alt='カートの中身が入っていません。'
              style={{ width: '100%', height: 'auto' }}
            />
          </Block>
          <Block margin='4rem 2rem'>
            <Button onClick={handleClickGoTop} {...buttonSecondary}>
              <span>商品を見る</span>
            </Button>
          </Block>
        </>
      )}
      {props.cartItems.length > 0 && (
        <>
          <Block padding='1.5rem'>
            <TakeoutInfo>
              <Block>
                <Label3>{props.restaurant.name}</Label3>
                <Paragraph4 color={theme.colors.primary400}>{props.restaurant.address}</Paragraph4>
                {/* {props.restaurant.isOpen && (
                <Label4>
                  できあがり目安：約 <Paragraph1 display='inline'>{receivableTime}分後</Paragraph1>
                </Label4>
              )} */}
              </Block>
              <Map mapUrl={props.restaurant.mapUrl} />
            </TakeoutInfo>
            {props.restaurant.openingTime && props.restaurant.isOpen !== 2 && (
              <ReceivedAtBlock>
                <Label3>受け取り時間</Label3>
                <Block width='calc(100% - 8rem)'>
                  <select style={selectBox} value={receivedAt} onChange={(e) => addReceivedAt(e.target.value)}>
                    {selectableTimes.map((time) => (
                      <option key={time.value} value={time.value}>
                        {time.label}
                      </option>
                    ))}
                  </select>
                </Block>
              </ReceivedAtBlock>
            )}
          </Block>
          <Block>
            {props.cartItems.map((ci: CartItemEntity) => (
              <CartItem
                key={ci.itemId}
                cartItem={ci}
                items={items}
                restaurant={props.restaurant}
                removeCartItem={removeCartItem}
              />
            ))}
          </Block>
          <Block margin='1rem'>
            <Payment>
              <Label4 marginBottom='0.5rem'>お支払い</Label4>
              <Paragraph3>商品受け渡し時に店頭でお支払いとなります。</Paragraph3>
              <Paragraph3>現金やクレジットやQR決済使用可能です。</Paragraph3>
              <Paragraph3>※店舗により異なります。</Paragraph3>
            </Payment>
          </Block>
          <Block margin='1rem'>
            <FlexGrid flexGridColumnCount={2} width='calc(100% - 2rem)' margin='auto'>
              <FlexGridItem {...category}>
                <Label1>合計（税込）</Label1>
              </FlexGridItem>
              <FlexGridItem {...price}>
                <Label1>{formatPrice(props.totalPrice)}円</Label1>
              </FlexGridItem>
            </FlexGrid>
          </Block>

          <Block margin='1rem'>
            {receivedAt !== undefined && (
              <Button onClick={handleClickGoRegister} {...buttonPrimary}>
                次へ進む
              </Button>
            )}
            {props.restaurant.isOpen !== 2 && props.restaurant.openingTime && !receivedAt && (
              <Button onClick={handleClickGoRegister} {...buttonPrimary} disabled={true}>
                受取時間を選択して下さい
              </Button>
            )}
            {(props.restaurant.isOpen === 2 || !props.restaurant.openingTime) && (
              <Button onClick={handleClickGoRegister} {...buttonPrimary} disabled={true}>
                ただいま受付できません
              </Button>
            )}
          </Block>
          <Block margin='1rem'>
            <Button onClick={handleClickGoTop} {...buttonSecondary}>
              <span>他の商品を見る</span>
            </Button>
          </Block>
        </>
      )}
    </>
  );
}

function makeSelectableTimes(restaurant: Restaurant): SelectableTime[] {
  const now = dayjs().set('minute', Math.ceil(dayjs().minute() / 10) * 10);
  const activeRange: ActiveRange[] = [];
  let deadline: dayjs.Dayjs = now;
  restaurant.openingTime.map((time) => {
    const start = dayjs(`${now.year()}-${now.month() + 1}-${now.date()} ${time.startAt}:00`);
    const end = dayjs(`${now.year()}-${now.month() + 1}-${now.date()} ${time.endAt}:00`);
    if (deadline.isBefore(end)) {
      deadline = end
    }
    if (now.isBefore(end)) {
        activeRange.push({ start: start, end: end});
    }
    return true;
  });

  if (!activeRange) {
    return [];
  }

  let cursor = now.clone().add(30, 'minute');
  const selectables: SelectableTime[] = [];
  while (!cursor.isAfter(deadline)) {
    cursor = cursor.add(10, 'minute');
    const isActive = checkIsActive(cursor, activeRange)
    if (isActive) {
      selectables.push(convertToSelectableTime(cursor));
    }
  }
  return selectables;
}

function checkIsActive(cursor: dayjs.Dayjs, activeRange: ActiveRange[]): ActiveRange|undefined {
  return activeRange.find(({start, end}) => {
    return cursor.isBetween(start, end);
  });
}

function convertToSelectableTime(time: dayjs.Dayjs): SelectableTime {
  return { label: time.format('HH:mm'), value: time.format('YYYY-MM-DD HH:mm:00') };
}

const TakeoutInfo = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '0.5rem',
  fontSize: '13px',
}));

const ReceivedAtBlock = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Payment = styled('div', ({ $theme }) => ({
  width: '100%',
  padding: '1rem',
  backgroundColor: $theme.colors.backgroundLightAccent,
}));

const category: BlockProps = {
  display: 'flex',
  paddingBottom: '0.5rem',
};
const price: BlockProps = {
  ...category,
  justifyContent: 'flex-end',
};

const buttonPrimary: ButtonProps = {
  size: SIZE.large,
  kind: KIND.primary,
  shape: SHAPE.pill,
  overrides: {
    BaseButton: {
      style: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
};

const buttonSecondary: ButtonProps = {
  ...buttonPrimary,
  size: SIZE.compact,
  kind: KIND.secondary,
};

const selectBox = {
  border: 'rgb(238, 238, 238)',
  borderWidth: 2,
  backgroundColor: 'rgb(238, 238, 238)',
  color: 'rgb(69, 76, 66)',
  fontSize: 16,
  lineHeight: 24,
  padding: 10,
  width: '100%',
};

function collectItems(restaurant: Restaurant) {
  const items: Item[] = [];
  restaurant.categories.forEach((c) => c.items.forEach((i) => items.push(i)));
  return items;
}
