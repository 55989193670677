import React from 'react';
// import styled, { css, keyframes } from 'styled-components';
// import { useInView } from 'react-intersection-observer';
import Seo from '@/components/Lp/Seo';
import { ThemeProvider } from 'emotion-theming';
import { Box, Text, Heading, Button, Flex, Image, Link } from 'rebass';
import theme from '@/components/Lp/Theme';
import GlobalStyle from '@/components/Lp/GlobalStyle';
import Header from '@/components/Lp/Header';
import Footer from '@/components/Lp/Footer';
import NewsLink from '@/components/Lp/NewsLink';

import Lottie from 'react-lottie';
import LottieAnimationOrders from '@/components/Lp/LottieOrders.json';
import IconDeviceTablet from '@/components/Svg/DeviceTablet';
import IconDevicePrinter from '@/components/Svg/DevicePrinter';
import IconDevicePos from '@/components/Svg/DevicePos';
// import ServiceLogoUberEats from '@/components/Svg/ServiceUbereats'
// import ServiceLogoComingsoon from '@/components/Svg/ServiceComingsoon'

type Props = {
  children: React.ReactNode;
  inView: boolean;
};

export default function Lp() {
  const lottieOptionOrders: any = {
    loop: true,
    autoplay: true,
    animationData: LottieAnimationOrders,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Seo />
      <ThemeProvider theme={theme}>
        <Box variant='body'>
          <Header />

          <Box as='section' sx={{ textAlign: 'center', pt: 6, backgroundColor: '#fff' }}>
            {/* <Text as="i" display='block' variant='h3'>for Restaurant</Text> */}
            <Box sx={{ mx: 'auto', maxWidth: ['85vw', null, null] }}>
              <Text fontSize='0'>for RESTAURANT, DINNING, CAFE</Text>
              <Heading as='h1' variant='title' my={1} mx={3}>
                デリバリー時代のための注文管理
              </Heading>
              {/* <Text as='h3' variant='h3'>テイクアウト・デリバリー・イートインを両立させて売上確保</Text> */}
              <Box>
                <Link href='/register'>
                  <Button mt={3} mb={[4, 1, 0]}>
                    資料を請求する
                  </Button>
                </Link>
              </Box>
            </Box>
            <Flex
              sx={{
                ...GlobalStyle.container,
                ...style.lottie,
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                top: '2rem',
              }}
            >
              {/* <Flex sx={style.services}>
              <ServiceLogoUberEats title='uberEats'/>
              <ServiceLogoComingsoon style={{color: theme.colors.red}} />
              <ServiceLogoComingsoon style={{color: theme.colors.primary}} />
            </Flex> */}
              <Box width={[300, null, 540]} sx={{ order: ['-1', 0, 0] }}>
                <Lottie options={lottieOptionOrders} />
              </Box>
              {/* <Box sx={{display:['none','block','block']}}>
              <Flex sx={style.services}>
                <ServiceLogoComingsoon style={{color: theme.colors.orange}} />
                <ServiceLogoComingsoon style={{color: theme.colors.primary}} />
                <ServiceLogoComingsoon style={{color: theme.colors.red}} />
              </Flex>
            </Box> */}
            </Flex>
          </Box>
          <Box sx={GlobalStyle.container} mt={5} py={4} px={4}>
            <Heading variant='h3' textAlign='center'>
              導入企業
            </Heading>
            <Flex
              as='p'
              sx={{
                flexDirection: ['column', 'row', 'row'],
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Image
                mx={[2, 3, 4]}
                mt={[4, null, null]}
                sx={style.clientLogo}
                src='images/lp/logo_kushitanaka.png'
                alt='串カツ田中'
              />
              <Image
                mx={[2, 3, 4]}
                mt={[4, null, null]}
                sx={style.clientLogo}
                src='images/lp/logo_yoshinoya.png'
                alt='吉野家'
              />
              <Image
                mx={[2, 3, 4]}
                mt={[4, null, null]}
                sx={style.clientLogo}
                src='images/lp/logo_cocoichi.png'
                alt='CoCo壱番屋'
              />
              <Image
                mx={[2, 3, 4]}
                mt={[4, null, null]}
                sx={style.clientLogo}
                src='images/lp/logo_mpkitchen.png'
                alt='MPキッチン'
              />
              <Image
                mx={[2, 3, 4]}
                mt={[4, null, null]}
                sx={style.clientLogo}
                src='images/lp/logo_fujiofoodsystem.png'
                alt='FUJIO FOOD SYSTEM'
              />
              {/* <Image
                mx={[2, 3, 4]}
                mt={[4, null, null]}
                sx={style.clientLogoVertical}
                src='images/lp/logo_gongcha.png'
                alt='Gong Cha'
              /> */}
            </Flex>
          </Box>
          <Box sx={{ ...GlobalStyle.container }} mt={5} px={3}>
            <Box sx={{ backgroundColor: 'white', py: 4 }}>
              <Heading variant='h3' sx={{ textAlign: 'center', mb: 3 }}>
                News
              </Heading>
              <Box sx={{ display: 'table', mx: 'auto', px: 3 }}>
                <NewsLink
                  date='2021.06.18'
                  url='https://www.notion.so/mol/Ordee-fe84d2c0ab6d48d3ae4ab1765899063e'
                  text='『月間食堂』にOrdeeが掲載されました'
                />
                <NewsLink
                  date='2021.06.15'
                  url='https://www.notion.so/mol/Ordee-08e427a6f04947f0ac14b3bc8aaf4423'
                  text='『飲食店経営』にOrdeeが掲載されました。'
                />
                <NewsLink
                  date='2021.04.22'
                  url='https://www.notion.so/mol/HERP-0a4c5c172a794d3493e681e1c3449b75'
                  text='HERP次世代スタートアップ注目求人特集に掲載されました'
                />
                <NewsLink
                  date='2021.03.31'
                  url='https://www.notion.so/mol/55296b532a014274b6e01d570346d459'
                  text='日経クロステックに掲載されました'
                />
                <NewsLink
                  date='2021.03.25'
                  url='https://www.notion.so/mol/CNET-be04e48af4d8471b9e211b96f1b427f2'
                  text='CNETに掲載されました。'
                />
                <NewsLink
                  date='2021.03.23'
                  url='https://www.notion.so/mol/DX-c4e4b12898b846b5a0131a8d4b29e21e'
                  text='串カツ田中様にDXシステムとして紹介されました'
                />
                <NewsLink
                  date='2021.01.13'
                  url='https://www.notion.so/mol/Ordee-1-2-7d5012d7ce2440199366a1c40ecbf91b'
                  text='プレスリリース：Ordee正式公開。1.2億円の資金調達を実施しました。'
                />
                <NewsLink
                  date='2020.11.9'
                  url='https://www.notion.so/mol/Ordee-7b54068b5d5243f982b3e14206836dc8'
                  text='日本経済新聞にOrdeeが掲載されました。'
                />
              </Box>
            </Box>
          </Box>
          <Box as='section' sx={{ ...GlobalStyle.container, textAlign: 'center' }} mt={5}>
            <Box px={[3, 5, 5]} py={5} variant='border.separate'>
              <Heading as='h2' variant='h2'>
                全てのデリバリーを１つにまとめる
              </Heading>
              <Text as='p' mt={3}>
                OrdeeはUberEatsなどのデリバリー・テイクアウト注文をまとめて管理する飲食店向けサービスです。
                <br />
                もう複数のタブレットに困らず集客できます。
              </Text>
              <Text fontSize='0' display='block' mt={4}>
                CHOICES
              </Text>
              <Box
                as='ul'
                sx={{
                  mx: 'auto',
                  display: 'grid',
                  gridGap: 3,
                  gridTemplateColumns: ['1', null, 'repeat(3, 1fr)'],
                  mt: 2,
                  px: [3, 0, 0],
                }}
              >
                <Box as='li' sx={style.choice}>
                  <IconDeviceTablet width='100%' height='70' />
                  <Heading as='p' variant='h3'>
                    タブレットにまとめる
                  </Heading>
                </Box>
                <Box as='li' sx={style.choice}>
                  <IconDevicePrinter width='100%' height='70' />
                  <Heading as='p' variant='h3'>
                    プリンターにまとめる
                  </Heading>
                </Box>
                <Box as='li' sx={style.choice}>
                  <IconDevicePos width='100%' height='70' />
                  <Heading as='p' variant='h3'>
                    POSにまとめる
                  </Heading>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box as='section' sx={GlobalStyle.container} my={6}>
            <Flex sx={style.flexReDirection}>
              <Box mx={3}>
                <Heading as='h2' variant='h2'>
                  withコロナの売上対策に。
                </Heading>
                <Text as='p' width={style.measure} mt={3} mb={4}>
                  デリバリー・テイクアウトサービスへの登録は、より多くのお客様にアピールすることにつながります。さらにゴーストレストランの追加で、売り上げに拍車をかけます。
                </Text>
                <Text textAlign='right' fontSize='0' mt={1}>
                  グラフは実際の月次売上実績。
                </Text>
              </Box>
              <Box>
                <Box sx={{ p: 3, backgroundColor: 'white' }}>
                  <Flex>
                    <Box m={3}>
                      <Text as='p'>ゴーストレストラン＋4社デリバリー増加で</Text>
                      <Heading as='p' variant='h2'>
                        売上2.5倍
                      </Heading>
                    </Box>
                  </Flex>
                  <Box m={3} maxWidth={['90%', '50%', '440px']}>
                    <Image src='/images/lp/graph.png' alt='デリバリー導入数が増えると売上も増加' />
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Box>

          <Box as='section' backgroundColor='black'>
            <Flex sx={style.flexReDirection} justifyContent='center' alignItems='center' py={[5]}>
              <Box width={['100%', 300, 500]}>
                <Image src='/images/lp/hell.jpg' alt='タブレット地獄' />
              </Box>
              <Box px={[3, 4, 5]} pt={[4, 0, 0]} color='white'>
                <Heading as='h2' variant='h2'>
                  デリバリー導入できない？
                  <br />
                  それ「タブレット地獄」かも
                </Heading>
                <Text as='p' variant='h3' my={3}>
                  □ タブレット置くために１席つぶしてる
                  <br />
                  □ 電源の管理が煩わしい
                  <br />□ 導入すると店内が混乱する
                </Text>
              </Box>
            </Flex>
          </Box>

          <Box as='section' my={6}>
            <Box mx='auto'>
              <Heading as='h2' variant='h2' pb={4} textAlign='center'>
                特徴
              </Heading>
              <Flex
                sx={{ ...GlobalStyle.container, ...style.flexReDirection }}
                justifyContent='space-between'
                alignItems='start'
              >
                <Box m={4}>
                  <Image
                    src='/images/lp/image_space.png'
                    width={['100%', 'auto', '336px']}
                    alt='導入が邪魔にならない'
                  />
                  <Heading as='h3' variant='h3' mt={3}>
                    見るタブレットが、少なくなる
                  </Heading>
                  <Text as='p' maxWidth={style.measure}>
                    タブレットを置く場所に困りません。デリバリーサービスのタブレットは、バックヤードに置いてOK。
                  </Text>
                </Box>
                <Box m={4} pt={[0, null, 5]}>
                  <Image
                    src='/images/lp/gohst_restaurant.png'
                    width={['100%', 'auto', '336px']}
                    alt='ゴーストレストラン'
                  />
                  <Heading as='h3' variant='h3' mt={3}>
                    ゴーストレストランにも対応
                  </Heading>
                  <Text as='p' maxWidth={style.measure}>
                    例えばUberEatsに店舗とゴーストレストランの２つを出店するとタブレットが２つ必要になりますが、Ordeeならタブレットを増やさずに済みます。
                  </Text>
                </Box>
              </Flex>
              <Flex
                sx={{ ...GlobalStyle.container, ...style.flexReDirection }}
                justifyContent='space-between'
                alignItems='start'
              >
                <Box m={4}>
                  <Image
                    src='/images/lp/image_operation.png'
                    width={['100%', 'auto', '336px']}
                    alt='プリンターで営業効率UP'
                  />
                  <Heading as='h3' variant='h3' mt={3}>
                    ハンディ打込み不要で、営業しやすい
                  </Heading>
                  <Text as='p' maxWidth={style.measure}>
                    プリンターなら調理開始までが早くなります。注文がきたらタブレットをタップしてハンディに打ち込み伝票に注文番号を記載する、この従来のフローを省略します。
                  </Text>
                </Box>
                <Box m={4} pt={[0, null, 5]}>
                  <Image src='/images/lp/image_cloud.png' width={['100%', 'auto', '336px']} alt='まとめて操作' />
                  <Heading as='h3' variant='h3' mt={3}>
                    品切れも受付停止も、まとめて操作
                  </Heading>
                  <Text as='p' maxWidth={style.measure}>
                    品切れ管理・受付停止・注文履歴の確認など、Ordeeのタブレットアプリ１つで各社サービスの操作をまとめて行えます。※一部サービスを除く
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Box>

          <Box as='section' sx={{ ...GlobalStyle.container }} mt={5}>
            <Box px={[3, 5, 5]} py={5} variant='border.separate'>
              <Flex px={GlobalStyle.view.gutter} justifyContent='center'>
                <Box as='dl' sx={style.questions}>
                  <Text as='dt' mt={2} variant='h3'>
                    Ordeeで導入できるサービスを教えてください
                  </Text>
                  <Text as='dd' mt={2}>
                    御社の状況で連携可能メディアが変わる可能性がございます、お問い合わせ下さい。
                  </Text>
                  <Text as='dt' mt={4} variant='h3'>
                    導入までのスケジュールを教えてください
                  </Text>
                  <Text as='dd' mt={2}>
                    契約後1週間〜トライアルが開始可能です。
                  </Text>
                  <Text as='dt' mt={4} variant='h3'>
                    POSとの連携は可能ですか？
                  </Text>
                  <Text as='dd' mt={2}>
                    開発予定です。まずはお問い合わせください。
                  </Text>
                  <Text as='dt' mt={4} variant='h3'>
                    １店舗から利用は可能ですか？
                  </Text>
                  <Text as='dd' mt={2}>
                    現在β版により、30店舗以上展開されている企業様に限定的に展開させていただいています。もう少々おまちください。
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Box>

          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
}

const style = {
  flexReDirection: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: ['column', 'row', 'row'],
  },
  lottie: {
    position: 'relative',
    height: 'auto',
    flexDirection: ['column', 'row', 'row'],
    // '::before': {
    //   content: [null,"''","''"],
    //   display: 'block',
    //   position: 'absolute',
    //   bottom: '2rem',
    //   width: '97%',
    //   height: '2px',
    //   backgroundColor: '#000',
    //   zIndex: '0'
    // }
  },
  services: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: ['row', 'column', 'column'],
    width: ['85%', 100, 140],
    mt: [3, null, null],
    ' > *': {
      mb: [4, 4, 4],
    },
  },
  clientLogo: {
    width: 'auto',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '40px',
  },
  clientLogoVertical: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100%',
    maxWidth: '100px',
  },
  choice: {
    backgroundColor: 'black',
    py: 3,
    px: 3,
    textAlign: 'center',
    color: 'white',
    listStyle: 'none',
    ' > svg': {
      my: 4,
    },
  },
  questions: {
    paddingLeft: '2em',
    position: 'relative',
    ' > dt:before': {
      content: '"Q."',
      position: 'absolute',
      left: 0,
    },
  },
  measure: ['100%', 'auto', '22em'],
};
