import React from 'react';
import useRouter from 'use-react-router';

const ScrollToTopOnPathChanges: React.FC = ({ children }) => {
  const router = useRouter();
  const [path, updatePath] = React.useState<string>(router.location.pathname);
  React.useEffect(() => {
    if (path !== router.location.pathname) {
      window.scrollTo(0, 0);
    }
    updatePath(router.location.pathname);
  }, [path, router.location.pathname]);
  return <>{children}</>;
};

export default ScrollToTopOnPathChanges;
