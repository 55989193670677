import * as React from 'react';

import Base from '@/components/Layouts/Base';
import PageTitle from '@/components/Common/PageTitle';
import { styled, ThemeProvider } from 'baseui';
import Theme from '@/constants/Theme';

const Wrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  width: '80vw',
  height: '80vw',
  justifyContent: 'center',
  alignItems: 'center',
  background: $theme.colors.accent,
  color: $theme.colors.contentOnColor,
  borderRadius: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '10vw',
}));

export default function NotFound() {
  const onClick = () => {
    throw new Error('oh!');
  };
  return (
    <>
      <PageTitle title='Page Not Found' />
      <Base>
        <ThemeProvider theme={Theme}>
          <Wrapper onClick={onClick}>Not Found</Wrapper>
        </ThemeProvider>
      </Base>
    </>
  );
}
