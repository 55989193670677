import React from 'react';
import GlobalStyle from '@/components/Lp/GlobalStyle';
import { Box, Text, Heading, Button, Flex, Link} from 'rebass';

export default function Seo() {
  return(
    <Box as="footer">
      <Box my="6" sx={{textAlign:'center'}}>
        <Heading as="h2" variant="h2" mb={3}>まずはトライアル導入してみませんか？</Heading>
        <Link href='/register'>
          <Button>資料を請求する</Button>
        </Link>
      </Box>
      <Box backgroundColor='black' color='white'>
        <Box sx={{...GlobalStyle.container}} pt={[4,5,5]} pb={[3,4,4]}>
          <Heading as='p' variant="h3" pb={2}>飲食店の経営者様</Heading>
          <Link variant='undelineLinkWhite' href='/register'>資料を請求する</Link>
          <Heading as='p' variant="h3" mt={4} pb={2}>デリバリー・テイクアウト事業者様</Heading>
          <Link variant='undelineLinkWhite' href='mailto:info@mobileorder.co.jp' target='_blank'>お問い合わせ</Link>
          <Flex mt={5} pt={5} justifyContent='space-between' sx={{flexFlow:['column-reverse','row','row']}}>
            <Text as='p' mt={3} fontSize='0'>©︎ Mobile Order Lab inc.</Text>
            <Box mt={3}>
              <Link variant='undelineLinkWhite' mr={3} href='https://drive.google.com/drive/folders/16H_3nRlr4opYT3rGWefGcq0GBlBz2M1b?usp=sharing'>メディアキット</Link>
              <Link variant='undelineLinkWhite' href='https://mobileorder.co.jp/'>運営会社</Link>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box> 
  )
}  