import React from 'react';
import { useStyletron } from 'baseui';
import theme from '../../constants/Theme';

const Heading: React.FC = ({ children }) => {
  const [css] = useStyletron();
  return <h2 className={css(Wrapper)}>{children}</h2>;
};

const Wrapper = {
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 0,
  borderTop: 'solid 1px' + theme.colors.border,
  padding: '1rem',
  fontSize: theme.typography.HeadingMedium,
};

export default Heading;
