import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'baseui';
import { Block } from 'baseui/block';
import { Delete } from 'baseui/icon';
import dayjs from 'dayjs';

import Base from '@/components/Layouts/Base';
import PageTitle from '@/components/Common/PageTitle';
import Theme from '@/constants/Theme';
import Hover from '@/components/Common/Hover';
import SvgCutlery from '@/components/Svg/Cutlery';
import SvgStore from '@/components/Svg/Store';
import Wrapper from '@/components/Common/Wrapper';
import CompleteAnimation from '@/components/Cart/CompleteAnimation';

import { H3, Label4, Paragraph3 } from 'baseui/typography';
import { useInternalLink } from '@/helpers/hooks';
import { useOrder } from '@/stores/order';
import { Order } from '@/typings/entity/order';
import NotFound from '../NotFound';

export default function Complete() {
  const linkToTop = useInternalLink();
  const order = useOrder();
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [created, setCreated] = React.useState<Order | null>(null);
  React.useEffect(() => {
    if (order.list.length === 0) {
      return;
    }
    const [first] = order.list.concat([]).sort((a, b) => b.id - a.id);
    setCreated(first);
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);
  if (!initialized) {
    return null;
  }
  if (!created) {
    return <NotFound />;
  }
  const receivedAt = dayjs(created.receivedAt);
  return (
    <>
      <PageTitle title='Complete' />
      <Base>
        <HeaderParts>
          <Hover>
            <Link to={linkToTop} style={{ color: Theme.colors.primaryA }}>
              <Delete size={24} />
            </Link>
          </Hover>
          <span>注文完了しました</span>
          <span />
        </HeaderParts>
        <Wrapper size='small'>
          <Circle>
            <Block color={Theme.colors.contentOnColor}>
              <CompleteAnimation width={100} height={100} />
            </Block>
          </Circle>
          <Block margin='1rem 3rem'>
            <Heading>
              <Block paddingBottom='0.5rem' display='flex' justifyContent='space-between' alignItems='flex-end'>
                <H3 marginBottom={0} marginTop='0.25rem'>
                  {receivedAt.format('HH:mm')}ごろ
                </H3>
                <Label4>できあがり目安</Label4>
              </Block>
            </Heading>
          </Block>
          <Block margin='2rem 3rem' display='flex' justifyContent='start' alignItems='start'>
            <SvgCutlery width='32px' height='32px' />
            <Paragraph3 marginLeft='1rem' display='flex' justifyContent='center'>
              おいしく料理を楽しめますよう5分前に来店ください
            </Paragraph3>
          </Block>
          <Block margin='2rem 3rem' display='flex' justifyContent='start' alignItems='start'>
            <SvgStore width='32px' height='32px' />
            <Paragraph3 marginLeft='1rem' display='flex' justifyContent='center'>
              受け取り時には、お名前をお伝えください
            </Paragraph3>
          </Block>
        </Wrapper>
      </Base>
    </>
  );
}

const Circle = styled('div', ({ $theme }) => ({
  width: '13rem',
  height: '13rem',
  margin: '3rem auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '100%',
  backgroundColor: $theme.colors.positive,
  color: $theme.colors.contentOnColor,
}));

const HeaderParts = styled('div', ({ $theme }) => ({
  height: '3.5rem',
  width: '100%',
  maxWidth: $theme.breakpoints.large,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '1rem',
  paddingRight: '2.25rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: $theme.lighting.shadow400,
}));

const Heading = styled('div', () => ({
  borderBottom: 'solid 1px #ddd',
}));
